// Colors
$color-rw-red: #d20014;
$color-white: white;
$color-light-gray: #f5f5f5;
$color-gray: #7f7f7f;
$color-gray-half: #AfAfAf;
$color-gray-quater: #ececec;
$color-dark-gray: #383838;
$color-black: black;
$color-black-1-10th: rgb(0, 0, 0, 0.1);
$color-black-15: #d9d9d9;
$color-black-90: rgb(25, 25, 25, 0.25);

$color-status-open: $color-rw-red;
$color-status-in-progress: #f6bb01;
$color-status-closed: #009933;

// Fonts
$base-font-size: 12px;

//size
$viewport-width: 1120px;