html, button, input {
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2rem;
  letter-spacing: normal;
}

@font-face {
  font-family: Avenir;
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url("../../assets/fonts/AvenirNextLTPro-Regular.eot?#iefix");
  src: url("../../assets/fonts/AvenirNextLTPro-Regular.eot?#iefix") format("eot"), url("../../assets/fonts/AvenirNextLTPro-Regular.woff2") format("woff2"), url("../../assets/fonts/AvenirNextLTPro-Regular.woff") format("woff"), url("../../assets/fonts/AvenirNextLTPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Avenir;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url("../../assets/fonts/AvenirNextLTPro-Medium.eot?#iefix");
  src: url("../../assets/fonts/AvenirNextLTPro-Medium.eot?#iefix") format("eot"), url("../../assets/fonts/AvenirNextLTPro-Medium.woff2") format("woff2"), url("../../assets/fonts/AvenirNextLTPro-Medium.woff") format("woff"), url("../../assets/fonts/AvenirNextLTPro-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Avenir;
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: url("../../assets/fonts/AvenirNextLTPro-Demi.eot?#iefix");
  src: url("../../assets/fonts/AvenirNextLTPro-Demi.eot?#iefix") format("eot"), url("../../assets/fonts/AvenirNextLTPro-Demi.woff2") format("woff2"), url("../../assets/fonts/AvenirNextLTPro-Demi.woff") format("woff"), url("../../assets/fonts/AvenirNextLTPro-Demi.ttf") format("truetype");
}
@font-face {
  font-family: Avenir;
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  src: url("../../assets/fonts/AvenirNextLTPro-Bold.eot?#iefix");
  src: url("../../assets/fonts/AvenirNextLTPro-Bold.eot?#iefix") format("eot"), url("../../assets/fonts/AvenirNextLTPro-Bold.woff2") format("woff2"), url("../../assets/fonts/AvenirNextLTPro-Bold.woff") format("woff"), url("../../assets/fonts/AvenirNextLTPro-Bold.ttf") format("truetype");
}
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d20014;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d1a4a4;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d20014;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #d1a4a4;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #d20014;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #d1a4a4;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #d1a4a4;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d20014;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #d1a4a4;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d20014;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #d1a4a4;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d20014;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #d1a4a4;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(210, 0, 20, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(210, 0, 20, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(210, 0, 20, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #d20014;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(210, 0, 20, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(210, 0, 20, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(209, 164, 164, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(209, 164, 164, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(209, 164, 164, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #d1a4a4;
  color: #9f7575;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(209, 164, 164, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #9f7575;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(209, 164, 164, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #d20014;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #d1a4a4;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #d20014;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #d1a4a4;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #d20014;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #d1a4a4;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d20014;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #d20014;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d20014;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #d20014;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d20014;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #d1a4a4;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field {
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5;
}

.mat-form-field, .mat-calendar, .mat-option, .mat-checkbox {
  font-family: Avenir, Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body-label, .mat-option {
  font-weight: 800;
}

.mat-calendar-body-cell {
  font-weight: 700;
}

.cdk-overlay-pane {
  transform: translateX(0px) translateY(0px) !important;
}

.mat-select {
  font-family: Avenir;
}

.mat-select-panel {
  min-width: calc(100%) !important;
}

html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
}

.container {
  max-width: 1120px;
  width: 1120px;
  margin: auto;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.3333333333rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 3rem;
  letter-spacing: -0.29px;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 1.6666666667rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25rem;
  letter-spacing: -0.25px;
  padding: 0;
  margin: 0;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}
button:hover {
  background: none;
}
button:focus {
  outline: 0;
}

.hidden {
  visibility: hidden;
}

.no-display {
  display: none !important;
}

rw-button .rw-button.Open, rw-button .rw-button.submit {
  color: white;
  background-color: #d20014;
}
rw-button .rw-button.InProgress {
  background-color: #f6bb01;
}
rw-button .rw-button.big {
  line-height: 40px;
  height: 40px !important;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 2px;
}