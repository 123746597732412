html, button, input {
  font-family: Avenir;
  font-size: $base-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: rem(24px);
  letter-spacing: normal;
}

@font-face {
	font-family: Avenir; // 'Avenir Next W07 Regular'
	font-weight: 500;
	font-display: swap;
	font-style: normal;
	src: url('../../assets/fonts/AvenirNextLTPro-Regular.eot?#iefix');
	src: url('../../assets/fonts/AvenirNextLTPro-Regular.eot?#iefix') format('eot'),
		 url('../../assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirNextLTPro-Regular.woff') format('woff'),
		 url('../../assets/fonts/AvenirNextLTPro-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Avenir;  // 'Avenir Next W07 Medium'
	font-weight: 700;
	font-display: swap;
	font-style: normal;
	src: url('../../assets/fonts/AvenirNextLTPro-Medium.eot?#iefix');
	src: url('../../assets/fonts/AvenirNextLTPro-Medium.eot?#iefix') format('eot'),
		 url('../../assets/fonts/AvenirNextLTPro-Medium.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirNextLTPro-Medium.woff') format('woff'),
		 url('../../assets/fonts/AvenirNextLTPro-Medium.ttf') format('truetype');
}

@font-face {
	font-family: Avenir; // 'Avenir Next W07 Demi'
	font-weight: 800;
	font-display: swap;
	font-style: normal;
	src: url('../../assets/fonts/AvenirNextLTPro-Demi.eot?#iefix');
	src: url('../../assets/fonts/AvenirNextLTPro-Demi.eot?#iefix') format('eot'),
		 url('../../assets/fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirNextLTPro-Demi.woff') format('woff'),
		 url('../../assets/fonts/AvenirNextLTPro-Demi.ttf') format('truetype');
}

@font-face {
	font-family: Avenir; // 'Avenir Next W07 Bold'
	font-weight: 900;
	font-display: swap;
	font-style: normal;
	src: url('../../assets/fonts/AvenirNextLTPro-Bold.eot?#iefix');
	src: url('../../assets/fonts/AvenirNextLTPro-Bold.eot?#iefix') format('eot'),
		 url('../../assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirNextLTPro-Bold.woff') format('woff'),
		 url('../../assets/fonts/AvenirNextLTPro-Bold.ttf') format('truetype');
}