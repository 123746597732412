@import 'variables';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: (
 Main:#d20014,
 Light:#ff503e,
 Dark: #980000,
 contrast: (
  Main: #fff,
  Light: #000,
  Dark: #fff
 ));

$accent: (
 Main:#d1a4a4,
 Light:#ffd5d5,
 Dark: #9f7575,
 contrast: (
  Main: #9f7575,
  Light: #000,
  Dark: #fff
 ));

$warn: (
 Main:#f6bb01,
 Light:#ffed4d,
 Dark: #be8b00,
 contrast: (
  Main: #fff,
  Light: #000,
  Dark: #fff
 ));


$rw-primary: mat-palette($primary, Main, Light, Dark);
$rw-accent: mat-palette($accent, Main, Light, Dark);
// The warn palette is optional (defaults to red).
$rw-warn: mat-palette($warn, Main, Light, Dark);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rw-theme: mat-light-theme((
  color: (
    primary: $rw-primary,
    accent: $rw-accent
  )
));

$rw-check-theme: mat-light-theme((
  color: (
    primary: $rw-primary,
    accent: $rw-primary
  )
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($rw-theme);

@include mat-core-theme($rw-theme);
@include mat-form-field-theme($rw-theme);
@include mat-datepicker-theme($rw-theme);
@include mat-input-theme($rw-theme);
@include mat-progress-spinner-theme($rw-theme);
@include mat-autocomplete-theme($rw-theme);
@include mat-checkbox-theme($rw-check-theme);
@include mat-select-theme($rw-theme);

.mat-form-field {
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5;
}

// .ng-invalid {
//   color: $color-status-in-progress;
// }

.mat-form-field, .mat-calendar, .mat-option, .mat-checkbox {
  font-family: Avenir, Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body-label, .mat-option {
  font-weight: 800;
}

.mat-calendar-body-cell {
  font-weight: 700;
}

.cdk-overlay-pane {
  transform: translateX(0px) translateY(0px) !important;
}

.mat-select {
  font-family: Avenir;
}

.mat-select-panel {
  min-width: calc(100%) !important;
}